import {
  MutationCreateStockArgs,
  Product,
  QueryProductsArgs,
  Stock,
} from "@/graphql/types";
import { gql } from "@apollo/client/core";
import { useMutation } from "@vue/apollo-composable";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";
import { STOCK_FIELDS } from "@/graphql/stock/stock";
import { useValidation } from "vue3-form-validation";
import { PRODUCTS, ProductsData } from "@/graphql/product/products";
import { activeActivity } from "@/plugins/i18n";
import { cloneDeep, formatDate, trim } from "@/graphql/utils/utils";
import moment from "moment";

type CreateStockData = {
  createStock: Stock;
};

const CREATE_STOCK = gql`
  mutation CreateStock($input: CreateStockInput!){
    createStock(input: $input) {
      ${STOCK_FIELDS}
    }
  }
`;
export const useCreateStock = (callback: () => void) => {
  const toast = useToast();
  const { t } = useI18n();
  const { form, resetFields, validateFields } = useValidation({
    note: {
      $value: "",
      $rules: [(n: string) => !!n && n.length > 255 && t("stock.noteError")],
    },
    productId: {
      $value: 0,
    },
    activityId: {
      $value: 0,
    },
    vat: {
      $value: activeActivity.value.taxRate,
    },
    exclTax: {
      $value: 0,
      $rules: [(n: number) => isNaN(n) && ""],
    },
    inclTax: {
      $value: 0,
      $rules: [(n: number) => isNaN(n) && ""],
    },
    purchasePrice: {
      $value: 0,
      $rules: [(n: number) => isNaN(n) && ""],
    },
    quantity: {
      $value: 0,
      $rules: [(n: number) => isNaN(n) && ""],
    },
    alertAt: {
      $value: null,
      $rules: [() => checkDates() && ""],
    },
    expiredAt: {
      $value: null,
      $rules: [() => checkDates() && ""],
    },
  });

  function checkDates() {
    if (form.alertAt.$value && form.expiredAt.$value) {
      return moment(form.alertAt.$value).isAfter(form.expiredAt.$value);
    }
    return false;
  }
  const { loading, mutate, onDone, onError } = useMutation<
    CreateStockData,
    MutationCreateStockArgs
  >(CREATE_STOCK);
  onDone(({ data }) => {
    const success = !!data?.createStock;
    toast.add({
      severity: success ? "success" : "warn",
      summary: t("stock.newEntry.title"),
      detail: t(success ? "stock.newEntry.success" : "unknownError"),
      life: parseInt(process.env.VUE_APP_TOAST_LIFE),
    });
    if (success) {
      resetFields();
      callback();
    }
  });
  onError(() => {
    toast.add({
      severity: "warn",
      summary: t("stock.newEntry.title"),
      detail: t("networkError"),
      life: parseInt(process.env.VUE_APP_TOAST_LIFE),
    });
  });
  function submitCreation(productId: number) {
    validateFields().then((input) => {
      trim(input);
      input.productId = productId;
      input.activityId = activeActivity.value.id;
      input.expiredAt = form.expiredAt.$value
        ? formatDate(form.expiredAt.$value)
        : null;
      input.alertAt = form.alertAt.$value
        ? formatDate(form.alertAt.$value)
        : null;

      void mutate(
        { input },
        {
          update: (cache, { data }) => {
            if (data?.createStock) {
              const stock = data.createStock;
              const products: Product[] = cloneDeep(
                cache.readQuery<ProductsData, QueryProductsArgs>({
                  query: PRODUCTS,
                  variables: { activityId: activeActivity.value.id },
                }).products
              );
              const index = products.findIndex((p) => p.id === productId);
              const exist = products[index]?.stocks.find(
                (s) => s.id === stock.id
              );
              if (!exist) {
                products[index].stocks.unshift(stock);
                cache.writeQuery<ProductsData, QueryProductsArgs>({
                  query: PRODUCTS,
                  data: { products },
                  variables: { activityId: activeActivity.value.id },
                });
              }
            }
          },
        }
      );
    });
  }
  return {
    submitCreation,
    loading,
    form,
  };
};
